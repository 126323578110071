<div class="editor-component">
    @if(currentFormStore.isLoading() || !form){
        ...loading
    } @else {
        <form [formGroup]="form" (ngSubmit)="save()" >
            <p-card [header]="formHeader">
                <div class="form-control-wraper">
                    <span class="p-float-label">
                        <input pInputText  formControlName="title" id="title"/>
                        <label for="title">Title</label>
                    </span>
                </div>
                
                <div class="form-control-wraper">
                    <span class="p-float-label">
                        <input pInputText formControlName="company" id="company"/>
                        <label for="company">Company</label>
                    </span>
                </div>
                <div class="form-control-wraper">
                    <span class="p-float-label">
                        <p-dropdown id="status" formControlName="status" [options]="statuses" placeholder="Status" optionLabel="name"></p-dropdown>
                        <label for="status">Status</label>
                    </span>
                </div>
                
                <div class="form-control-wraper">
                    <span class="p-float-label">
                        <input pInputText  formControlName="logo" id="logo"/>
                        <label for="logo">logo url</label>
                    </span>
                </div>
            </p-card>
            <p-panel formGroupName="systemGroup" [toggleable]="true" [collapsed]="true">
                <ng-template pTemplate="header">
                    <div class="flex align-items-center gap-2">
                        <span class="font-bold">System group</span>
                    </div>
                </ng-template> 
                <div class="form-control-wraper">
                    <div  class="system-wraper">
                        <span class="translation">
                            <span class="p-float-label">
                                <input pInputText type="text" id="startTitle" formControlName="startTitle"/>
                                <label for="startTitle">Start Title</label>
                            </span>
                        </span>
                        
                        <span class="translation">
                            <span class="p-float-label">
                                <input pInputText type="text" id="startSubTitle" formControlName="startSubTitle"/>
                                <label for="startSubTitle">Start Sub Title</label>
                            </span>
                        </span>
                        <span class="translation">
                            <span class="p-float-label">
                                <input pInputText type="text" id="startBtn" formControlName="startBtn"/>
                                <label for="startBtn">Start btn</label>
                            </span>
                        </span>
                        <span class="translation">
                            <span class="p-float-label">
                                <input pInputText type="text" id="questionHeader" formControlName="questionHeader"/>
                                <label for="questionHeader">Question</label>
                            </span>
                        </span>                        
                        <span class="translation">
                            <span class="p-float-label">
                                <input pInputText type="text" id="submit" formControlName="submit"/>
                                <label for="submit">Submit</label>
                            </span>
                        </span>
                        <span class="translation">
                            <span class="p-float-label">
                                <input pInputText type="text" id="finishTitle" formControlName="finishTitle"/>
                                <label for="finishTitle">Finish title</label>
                            </span>
                        </span>
                        <span class="translation">
                            <span class="p-float-label">
                                <input pInputText type="text" id="finishSubTitle" formControlName="finishSubTitle"/>
                                <label for="finishSubTitle">Finish subtitle</label>
                            </span>
                        </span>
                        <span class="translation">
                            <span class="p-float-label">
                                <input pInputText type="text" id="sec" formControlName="sec"/>
                                <label for="sec">sec</label>
                            </span>
                        </span>
                    </div> 
                </div> 
            </p-panel>
            <p-card formGroupName="intro" header="Infro">
                <textarea rows="5" [style]="{'width':'100%'}" pInputTextarea formControlName="text"></textarea>
            </p-card>
            <div formArrayName="questions">
            @for(item of questions.controls; track item; let index = $index){
                <div [formGroupName]="index">
                    <p-panel [toggleable]="true">
                        <ng-template pTemplate="header">
                            <div class="flex align-items-center gap-2">
                                <span class="font-bold">{{form.get('systemGroup')!.get('questionHeader')!.value}}</span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="icons">
                            
                            <span *ngIf="!item.get('shareable')?.value" class="pi pi-eye-slash"></span>
                            <button *ngIf="index > 0" class="p-panel-header-icon p-link mr-2" [disabled]="!!reviews" (click)="deleteQuestion(index)">
                                <span class="pi pi-trash"></span>
                            </button>
                        </ng-template>
                        
                        <div class="form-control-wraper margin-bottom-30">
                            <p-checkbox   formControlName="shareable" [binary]="true" inputId="priv"></p-checkbox>
                            <label for="priv"> shareable (share answer with public link) </label>
                            <p>
                        </div>
                        
                        <div class="form-control-wraper">
                            <span class="p-float-label">
                                <p-dropdown id="qType" (onChange)="typeChange(item)" formControlName="questionType" [options]="qType" placeholder="Type" optionLabel="name" ></p-dropdown>
                                <label for="qType">Type</label>
                            </span>
                        </div>
                        <div>
                        @if(item.get('questionType')?.value?.code && item.get('questionType')?.value?.code !== 'videoBlitz'){
                        <div class="form-control-wraper">
                            <span class="p-float-label">
                                <input [style]="{'width':'100%'}" pInputText formControlName="text" id="text"/>
                                <label for="text">Question</label>
                            </span>
                        </div>

                            <div class="form-control-wraper">
                                <span class="p-float-label">
                                    <input [style]="{'width':'150px'}" pInputText formControlName="sec" id="sec"/>
                                    <label for="sec">Seconds for answer</label>
                                </span>
                            </div>
                        }
                        </div>
                        @if(item.get('questionType')?.value?.code && item.get('questionType')?.value?.code !== 'video'){
                            <div class="form-control-wraper" *ngIf="getOptions(item) && getOptions(item).length > 0">
                                <span>Options:</span>
                            </div>
                            <div formArrayName="options">
                                @for(option of getOptions(item); track option; let index = $index){
                                    <div class="flex-row-wrapper" [formGroupName]="index">
                                        <span class="p-float-label" [style]="{'flex':'1'}">
                                            <input [style]="{'width':'98%'}" pInputText formControlName="text"/>
                                            <label>Option</label>
                                        </span>
                                        @if(item.get('questionType')?.value?.code && item.get('questionType')?.value?.code === 'videoBlitz'){
                                            <span class="p-float-label">
                                                <input [style]="{'width':'150px'}" pInputText formControlName="sec"/>
                                                <label>Seconds for answer</label>
                                            </span>
                                        }
                                    </div>
                                }
                            </div>
                            <div class="btn-wrapper">
                                <p-button [disabled]="!!reviews" (click)="addOption(item)" class = "add-btn" icon="pi pi-plus" label="Add option" [style]="{'width':'calc(100% - 20px)','margin': '5px'}"></p-button>
                            </div> 
                        }
                    </p-panel>
                </div>
            }
            </div>
            <div class="btn-wrapper">
                <p-button [disabled]="!!reviews" (click)="addQuestion()" class = "add-btn" icon="pi pi-plus" label="Add question" [style]="{'width':'calc(100% - 20px)','margin': '5px'}"></p-button>
            </div>
            <p-card formGroupName="finish" header="Finish">
                <textarea rows="2" [style]="{'width':'100%'}" pInputTextarea formControlName="text"></textarea>
            </p-card>
            <div class="btn-wrapper">
                <p-button type="submit" class = "add-btn" icon="pi pi-plus" [label]="saveBtnTxt" [style]="{'float':'right'}"></p-button>
            </div> 
            </form>
    }
    
</div>