<div class="question-card h-full">
    <p-panel class="h-full" >
            <ng-template pTemplate="header">
                <div class="flex align-items-center gap-2 max-wid">
                    <span class="font-bold">{{data.systemGroup.questionHeader}}</span>
                    <i *ngIf="timer>0" class="pi pi-clock mr-4 p-text-secondary" pBadge style="font-size: 2rem" [value]="timer+data.systemGroup.sec"></i>
                </div>
            </ng-template>
            <div class="min-heigh">
                <p class="m-0"  class="question-text" *ngIf="data && data?.questionType !=='videoBlitz'" >
                    {{data.text}}
            </p>
            </div>
        
            <div class="content-container" [ngSwitch]="questionData?.questionType">
                <app-video-blitz [data]="data"   (videoStarted)="started()" (userChoise)="setUserChoise($event)" *ngSwitchCase="'videoBlitz'"></app-video-blitz>
                <app-video-answer (videoStarted)="started()" (userChoise)="setUserChoise($event)"  *ngSwitchCase="'video'" [data]="data"></app-video-answer>
                <app-check-box-question  (userChoise)="setUserChoise($event)" [data]="data" *ngSwitchCase="'checkboxSingle'"></app-check-box-question>
                <app-check-box-question (userChoise)="setUserChoise($event)" [data]="data" *ngSwitchCase="'checkboxMultiple'"></app-check-box-question>
                <app-input-text-question (userChoise)="setUserChoise($event)" [data]="data" *ngSwitchCase="'inputText'"></app-input-text-question>
            </div>
            <ng-template pTemplate="footer" >
                <div class="flex flex-wrap align-items-center justify-content-between gap-3 h-3rem ">
                    <p-button [label]="data.systemGroup.submit"  [disabled]="!showSubmit" (click)="submit()" [style]="{float:'right'}"></p-button>    
                </div>
            </ng-template>
    </p-panel>
</div>