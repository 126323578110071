@if(answersStore.isLoading()){
    <div>loading...</div>
} @else {
    <p-table [value]="answersStore.answers()!.list"   [rowHover]="true" >
        <ng-template pTemplate="header">
            <tr>
                <th>Name</th>
                <th>Time ago</th>
                <th>Status</th>
                <th>Public link</th>
                <th>Review</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-answer>
            <tr>
                <td><p-button icon="pi pi-user" [rounded]="true" (click)="goTo(answer.userId)" ></p-button></td>
                <td><p-chip class="chip-cls" [label]="answer.date | dateAgo"></p-chip></td>
                <td><p-tag [value]="answer.status" [severity]="getSeverity(answer.status)"></p-tag></td>
                <td>
                    <p-inputGroup>
                        <input  pInputText disabled="true" type="text"  class="p-inputtext-sm"  [style]="{'width':'30px','height':'27px'}" [value]="answer.publicLink" placeholder="Keyword" />
                        <button type="button"  [style]="{'height':'27px'}" (click)="saveToBuffer(answer.publicLink)" pButton icon="pi pi-copy" class="p-button-warning"></button>
                    </p-inputGroup>
                </td>
                <td><p-rating [disabled]="true" [ngModel]="answer.review" [readonly]="false" [cancel]="false"></p-rating></td>
            </tr>
        </ng-template>
</p-table>
}