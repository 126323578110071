<div class="login-component">
    <div class="surface-card p-4 shadow-2 border-round w-full lg:w-6">
    <div class="text-center mb-5">
        <img src="assets/city-svgrepo-com.svg" alt="Image" height="50" class="mb-3">
        <div class="text-900 text-3xl font-medium mb-3">Welcome Back</div>
        <span class="text-600 font-medium line-height-3">Don't have an account?</span>
        <a [routerLink]="'/register'" class="font-medium no-underline ml-2 text-blue-500 cursor-pointer">Create today!</a>
    </div>

    <div class="form-container">
        <label for="email1" class="block text-900 font-medium mb-2">Email</label>
        <input id="email1" type="text" [(ngModel)]="mail" placeholder="Email address" pInputText class="w-full mb-3">

        <label for="password1" class="block text-900 font-medium mb-2">Password</label>
        <p-password [(ngModel)]="pass" [feedback]="false" class="w-full mb-3 pass-full">
        </p-password>

        <!-- <div class="flex align-items-center justify-content-between mb-6">
            <div class="flex align-items-center">
                <p-checkbox id="rememberme1" [binary]="true" styleClass="mr-2"></p-checkbox>
                <label for="rememberme1" class="text-900">Remember me</label>
            </div>
            <a class="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer">Forgot password?</a>
        </div> -->

        <button (click)="login()" pButton pRipple label="Sign In" icon="pi pi-user" class="w-full"></button>
    </div>
</div>
</div>