<div class="grid grid-nogutter surface-section text-800">
    <div class="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center ">
        <section>
            <span class="block text-6xl font-bold mb-1">Vidify - online video exams</span>
            <div class="text-6xl text-primary font-bold mb-3">collect video responses from your potential employees</div>
            <p class="mt-0 mb-4 text-700 line-height-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            
            <button [routerLink]="'/login'" pButton pRipple label="Login" type="button" class="mr-3 p-button-raised"></button>
            <button [routerLink]="'/exam/demo'" pButton pRipple label="Demo Exam" type="button" class="p-button-outlined"></button>
        </section>
    </div>
    <div class="col-12 md:col-6 overflow-hidden rad">
        <img src="assets/hero-1.jpg" alt="Image" class="md:ml-auto block md:h-full" style="clip-path: polygon(8% 0, 100% 0%, 100% 100%, 0 100%)">
    </div>
</div>
