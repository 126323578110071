<div class="login-component">
    <div class="surface-card p-4 shadow-2 border-round w-full lg:w-6">
    <div class="text-center mb-5">
        <img src="assets/city-svgrepo-com.svg" alt="Image" height="50" class="mb-3">
        <div class="text-900 text-3xl font-medium mb-3">Welcome Back</div>
        <span class="text-600 font-medium line-height-3">Already have an account?</span>
        <a [routerLink]="'/login'" class="font-medium no-underline ml-2 text-blue-500 cursor-pointer">Let's login</a>
    </div>
<form  [formGroup]="form" (ngSubmit) = "register()">
    <div class="form-container">
        <label for="name" class="block text-900 font-medium mb-2">Your name</label>
        <input id="name" type="text" formControlName="name" placeholder="Your name" pInputText class="w-full mb-3">

        <label for="login" class="block text-900 font-medium mb-2">Email</label>
        <input id="login" type="text" formControlName="login" placeholder="Email address" pInputText class="w-full mb-3">

        <label for="password1" class="block text-900 font-medium mb-2">Password</label>
        <p-password formControlName="password" [toggleMask]="true" promptLabel="Choose a password" weakLabel="Too simple" mediumLabel="Average complexity" strongLabel="Complex password" [feedback]="true" class="w-full mb-3 pass-full">
        </p-password>

        <label for="password1" class="block text-900 font-medium mb-2">Confirm Password</label>
        <p-password formControlName="confirmPassword" [feedback]="false" class="w-full mb-3 pass-full">
        </p-password>

        <label for="photo" class="block text-900 font-medium mb-2">Link to your photo</label>
        <input id="photo" type="text" formControlName="photo" placeholder="Link to your photo" pInputText class="w-full mb-3">

        <button  type="submit" [disabled]="form.invalid" pButton pRipple label="Register user" icon="pi pi-user" class="w-full"></button>
    </div>
</form>
</div>
</div>